import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import YoutubeEmbed from "../components/YoutubeEmbed";
import FreeResources from "../components/FreeResources";
// import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';
import Seo from '../components/seo';

export const query = graphql`
    query($id: ID!) {
        wpcontent {
            webinar(id: $id) {
                title
                uri
                excerpt
                content
                youtubeId
                seo {
                    metaDesc
                    canonical
                    title
                    opengraphTitle
                    opengraphDescription
                    opengraphType
                    twitterTitle
                    twitterDescription
                }
            }
        }
    }
`

const WebinarTemplate = ({ data }) => {
    
    const webinar = data.wpcontent.webinar
    const metaDesc = data.wpcontent.webinar.seo.metaDesc
    const pageTitle = data.wpcontent.webinar.seo.title
    let uri = data.wpcontent.webinar?.uri
    
    let baseUrl = "https://www.vantagecircle.com";

    let pageUri = baseUrl + uri
    // const pageType = data.wpcontent.webinar.seo.opengraphType

    // const [open, setOpen] = useState(false);

    // const onOpenModal = () => setOpen(true);
    // const onCloseModal = () => setOpen(false);
    // let pageurl = `https://www.vantagecircle.com`
    // const uri = webinar.uri
    // if(uri){
    //     pageurl+= `${uri}`
    // }

    return (
        <Layout className="webinar-detail">
            <Seo title={pageTitle}
            description={metaDesc}
            meta={[
                {
                  name: `description`,
                  content: metaDesc,
                },
                {
                  property: `og:title`,
                  content: pageTitle,
                },
                {
                  property: `og:description`,
                  content: metaDesc,
                },
                {
                  name: `twitter:title`,
                  content: pageTitle,
                },
                {
                  name: `twitter:description`,
                  content: metaDesc,
                },
              ]}
              link={[
                  {
                      rel: `canonical`,
                      href: pageUri,
                  },
              ]}
            />
            <section className="bg-purple-50 mx-auto py-10 pb-0 sm:py-20 sm:pb-20 relative">
                <div className="container max-w-5xl sm:text-center px-6 sm:px-6 lg:px-6">
                    <div className="site-breadcrumb sm:text-center mb-5 text-gray-200">
                        <Link to="/" className="hover:underline">Home</Link>&nbsp;>>&nbsp;
                        <Link to="/webinars/" className="hover:underline">Webinars</Link>&nbsp;>>&nbsp; 
                        <span className="" dangerouslySetInnerHTML={{ __html: webinar.title }} />
                    </div>
                    <h1 className="hero-title-2" dangerouslySetInnerHTML={{ __html: webinar.title }} />
                    <p className="section-subtitle" dangerouslySetInnerHTML={{ __html: webinar.excerpt }} />
                </div>
                <img className="absolute z-1 hero-iconsl1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/06/Webinar-Hero-L1.png" alt="Webinars" />
                <img className="absolute z-1 hero-iconsl2 hero-icons-bottom hero-icons-1" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/06/Webinar-Hero-L2.png" alt="Webinars" />
                <img className="absolute z-1 hero-iconsr1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/Webinar-Hero-R1.png" alt="Webinars" />
                <img className="absolute z-1 hero-iconsr2 hero-icons-bottom hero-icons-1" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/Webinar-Hero-R2.png" alt="Webinars" />
            </section>
            <div className="slant-bg bg-purple-50 slant-bg-purple py-2 sm:py-5 md:py-8 lg:py-10"><div className="">&nbsp;</div></div>
            <section className="container max-w-4xl pb-20 md:-mt-36 bg-white">
                <YoutubeEmbed embedId={webinar.youtubeId} />
            </section>
            {/* <section className="flex items-center flex-wrap mt-10 mx-auto sm:mt-12 md:mt-16 lg:mt-10 xl:mt-20">
                <div className="container max-w-4xl px-4 sm:px-6 lg:px-8">
                    <h2 className="mt-2 text-3xl font-bold text-center text-gray-900 sm:text-4xl">
                        Guest Speakers
                    </h2>
                    <div className="mt-20 mb-32 grid grid-cols-1 gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                        <div className="card-1 w-full mb-1">
                            
                            <h2 className="mt-2 text-3xl font-semibold text-gray-900 sm:text-4xl">Company's <span className="text-orange">Mission</span></h2>
                            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto md:mt-5 md:text-xl lg:mx-0">The new age workforce have expectations from their employers that cannot be fulfilled by using traditional strategies.</p>
                            
                        </div>
                        <div className="card-1 w-full mb-1">
                            
                            <h2 className="mt-2 text-3xl font-semibold text-gray-900 sm:text-4xl">Company's <span className="text-orange">Vision</span></h2>
                            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto md:mt-5 md:text-xl lg:mx-0">The new age workforce have expectations from their employers that cannot be fulfilled by using traditional strategies.</p>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="container max-w-5xl px-4 sm:px-6 lg:px-8 md:pt-10">
                <h2 className="mb-10 text-3xl font-bold text-center text-gray-900 sm:text-4xl">
                    About the Webinar
                </h2>
                <div className="mt-3 section-subtitle" dangerouslySetInnerHTML={{ __html: webinar.content }} /><br/>
                
                {/* <button onClick={onOpenModal}>Open modal</button>
                <Modal open={open} onClose={onCloseModal} center className="w-11/12">
                    <div className="aspect-w-16 aspect-h-9">
                    atch the Video atch the Video atch the Video
                    </div>
                </Modal> */}
            </section> 
            <div className="md:-mt-20 md:pb-0">
                <FreeResources />
            </div>
            <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className="container max-w-5xl bg-purple-300 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden md:-bottom-10 lg:-bottom-32">
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section>
            
        </Layout>
    )
}

export default WebinarTemplate

